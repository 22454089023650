<template>
  <v-app-bar  app elevate-on-scroll elevation="3" color="white">
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

    <v-col lg="1" cols="12">
      <a
          href="/"
      ><v-img
          :src="require('../assets/Bouton retour.png')"
          contain
          max-height="34"
      /></a>
    </v-col>
    <v-col>
      <v-div class=""><v-img
          src="https://firebasestorage.googleapis.com/v0/b/frontvue-fiveaday.appspot.com/o/design%2FH1-catalogue%402x-100.jpg?alt=media&token=b4963f7a-640f-4823-a3e7-5977b2ccf9b3"
          contain
          max-height="50"
          min-width="600"
      /></v-div>
    </v-col>
    <router-link :to="{name: 'Cart'}" class="button teal--text">
      <v-img src="https://firebasestorage.googleapis.com/v0/b/frontvue-fiveaday.appspot.com/o/design%2FPanier-catalogue.svg?alt=media&token=f77837bc-dbeb-4350-aa2f-886805e492fe" elevation="2" height="20px" width="20px"></v-img>({{this.$store.state.cart.length}})
    </router-link>
  </v-app-bar>
</template>

<script>

export default {
  name: "Topbar",

};
</script>

<style scoped></style>