<template>
  <div>
    <h3>Categories</h3>

    <div
        v-for="category in sortedCategories"
        v-bind:key="category.category_id"
        class="collection"
    >
      <div
          v-if="editId === category.category_id"
          class="collection-item categories__list-item"
          v-bind:class="{ 'yellow lighten-4': editId === category.category_id }"
      >
        <div>{{category.category_id}}</div>
        <div>
          <input
              class="validate"
              v-model="editCategoryData.name"
          />
        </div>
        <div>
          <input
              class="validate"
              v-model="editCategoryData.description"
          />
        </div>
        <div>
          <i
              @click="onEditSubmit"
              class="material-icons"
          >
            check
          </i>
          <i
              @click="onCancel"
              class="material-icons"
          >
            cancel
          </i>
        </div>
      </div>
      <div
          v-else
          class="collection-item categories__list-item"
      >
        <div>{{category.category_id}}</div>
        <div>{{category.name}}</div>
        <div>{{category.description}}</div>
        <div>
          <i
              @click="onEdit(category)"
              class="material-icons"
          >
            mode_edit
          </i>
          <i
              @click="onDelete(category.category_id)"
              class="material-icons"
          >
            delete
          </i>
          <router-link
              :to="{
              name: 'CategoryPage',
              params: { category_id: category.category_id }
            }"
          >
            <i
                class="material-icons"
            >
              search
            </i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoriesList',
  props: [
    'editId',
    'editCategoryData',
    'onCancel',
    'onDelete',
    'onEdit',
    'onEditSubmit',
    'sortedCategories'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.categories__list-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.categories__add-category {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.material-icons {
  cursor: pointer;
}
</style>