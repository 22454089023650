<template>
  <v-container>
      <v-card
          class="mx-auto text-center pa-5"
          max-width="600">
        <v-card-title>
          Ajouter un produit
        </v-card-title>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-text-field
              v-model="product.name"
              :counter="100"
              label="Nom du produit"
              required
          ></v-text-field>

          <v-textarea
              v-model="product.description"
              name="input-7-1"
              label="Description"
              hint="Provenance/Recette"
          ></v-textarea>

          <v-textarea
              v-model="product.conditionnement"
              name="input-7-1"
              label="Conditionnement"
              hint="x100/à l'unité"
          ></v-textarea>

          <v-text-field
              v-model.number="product.price"
              label="Prix"
          ></v-text-field>

          <v-select
              :items="category"
              label="Catégorie"
              v-model="product.category"
          ></v-select>

          <div class="form-group" >
            <label> Product Images</label>
            <input type="file" @change="uploadImage" class="form-control">
          </div>

          <div class="form-group d-flex" >
            <div class="p-1" v-for="(image, index) in product.images" :key="index">
              <div class="img-wrapp">
                <img :src="image" alt="" width="80px">
                <span class="delete-img" @click="deleteImage(image,index)">X</span>
              </div>

            </div>
          </div>

          <v-checkbox
              v-model="checkbox"
              :rules="[v => !!v || 'Vous êtes ok pour continuer!']"
              label="Etes-vous d'accord?"
              required
          ></v-checkbox>

          <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              @click="addProduct"
          >
            Ajouter produit
          </v-btn>

          <v-btn
              color="error"
              class="mr-4"
              @click="reset"
          >
            Annuler
          </v-btn>

        </v-form>
      </v-card>
      <v-card>
        <v-simple-table>
          <template v-slot:default>
            <thead> Listes des produits
            <tr>
              <th class="text-left">
                Name
              </th>
              <th class="text-left">
                Description
              </th>
              <th class="text-left">
                Price
              </th>
              <th class="text-left">
                Category
              </th>
              <th class="text-left">
                Image
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="product in products" :key="product.name"
            >
              <td>{{ product.name }}</td>
              <td>{{ product.description }}</td>
              <td>{{ product.price }}</td>
              <td>{{ product.category }}</td>
              <td><v-img :src="product.image[0]" max-height="50" max-width="50"/></td>
              <td>
                <v-btn color="teal" icon ><v-icon>mdi-loupe</v-icon></v-btn> |
                <v-dialog
                    v-model="dialog"
                    width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           v-on="on" color="yellow" icon ><v-icon>mdi-swap-horizontal</v-icon></v-btn>
                  </template>

                  <v-card>
                    <v-form>
                      <v-text-field
                          v-model="product.name"
                          :counter="10"
                          label="Name"
                          required
                      ></v-text-field>

                      <v-textarea
                          v-model="product.description"
                          name="input-7-1"
                          label="Description"
                          hint="Hint text"
                      ></v-textarea>

                      <v-text-field
                          v-model.number="product.price"
                          label="Prix"
                      ></v-text-field>

                      <v-file-input
                          v-model="product.file"
                          label="Images"
                          multiple
                          @change="uploadImage"
                      ></v-file-input>

                      <v-checkbox
                          v-model="checkbox"
                          :rules="[v => !!v || 'You must agree to continue!']"
                          label="Do you agree?"
                          required
                      ></v-checkbox>

                      <v-btn
                          :disabled="!valid"
                          color="success"
                          class="mr-4"
                      >
                        Modifier le produit
                      </v-btn>

                      <v-btn
                          color="error"
                          class="mr-4"
                          @click="reset"
                      >
                        Annuler
                      </v-btn>
                    </v-form>
                  </v-card>
                </v-dialog> |
                <v-btn color="red" icon @click="deleteProduct(product.name)" ><v-icon>mdi-delete</v-icon></v-btn>


              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
  </v-container>
</template>

<script>
// import { collection, addDoc, getDocs, query } from "firebase/firestore";
import {db, firebase} from "../main";
export default {
  name: "Products",
  data: () => ({
    valid: true,
    dialog: false,
    category:[
        'Communication','Fruits Secs','Textile','Petit Matériel','Boissons Chaudes','Evénementiel'
    ],
    products:[],
    product:{
      productId: null,
      name: '',
      description: '',
      conditionnement: '',
      price: null,
      category: "",
      file: "",
      images:[],
      colors: ["Marine","Rouge","Ciel","Jaune","Bleu"],
      sizes: ["XS","S","M","L","XL","XXL"]
    },
    uploadTask: null,
    uploadState: null,
    activeItem:null,
    checkbox: false,
  }),
  firestore(){
      return{
        products: db.collection("products"),
      }
  },
  methods: {
    async getProducts(){
      await db.collection("products").get().then((querySnapshot) => {
        // const products = []
          querySnapshot.forEach((doc) => {
            this.products.push(doc.data());
          });
        }).catch((error) => {
        console.log("Error getting documents: ", error);
      });
    },
    async addProduct () {
      await db.collection("products").add(
          {
            productId: null,
            name: this.product.name,
            description: this.product.description,
            conditionnement: this.product.conditionnement,
            price: this.product.price,
            image: this.product.images,
            category: this.product.category,
            size: this.product.sizes,
            color: this.product.colors
          }
      )
      .then(function (productRef) {
        console.log("Document written with ID: ", productRef.id);
        db.collection("products").doc(productRef.id).update({
          productId: productRef.id,
        }).then(() => {
          console.log("Document successfully updated!");
        }).catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      })
    },
    updateProduct(){
      db.collection("products")
          .where('name', '==', this.product.name).get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.set({
                name: this.product.name,
                description: this.product.description,
                price: this.product.price,
                image: this.uploadImage(),
                quantity: this.quantity
              }).then(this.getProducts)
            })
          })
    },
    deleteProduct(name){
      if(confirm("Etes-vous d'accord ?")){
        db.collection("products")
            .where('name', '==', name).get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                doc.ref.delete().then(this.getProducts)
              })
            })
      }
    },
    uploadImage(e){
      // let file = this.product.file
      let file = e.target.files[0];
      let storageRef = firebase.storage().ref("products/"+file.name)
      this.uploadTask = storageRef.put(file);
      this.uploadTask.on("state_changed", () =>{console.log("next step")
        this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) =>{
          this.product.images.push(downloadURL)
          console.log("File available at", downloadURL);
        })
      })
    },
    deleteImage(img,index){
      let image = firebase.storage().ref(img);
      
      this.product.images.splice(index,1);
      image.delete().then(() => {
        console.log("image supprimée");
      })
    },
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.remove()
    },
  },
  created(){
    this.getProducts();
  }
}
</script>

<style scoped>
.img-wrapp{
  position: relative;
}
.img-wrapp span.delete-img{
  position: absolute;
  top: -14px;
  left: -2px;
}
.img-wrapp span.delete-img:hover{
  cursor: pointer;
}
</style>