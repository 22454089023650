<template>
  <div>
    <div v-if="loaded">
      <h1>{{name}}</h1>
      <button class="btn">Buy</button>
    </div>
    <div v-else>
      <h1>Loading...</h1>
    </div>
  </div>
</template>

<script>
import {db} from "../../main";
export default {
  name: 'File',
  data () {
    return {
      name: '',
      description: '',
      loaded: false
    }
  },
  beforeCreate () {
    db.collection("Files")
        .where('file_id', '==', this.$route.params.file_id).get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const { name, description } = doc.data()
            this.name = name
            this.description = description
          })
          this.loaded = true
        })
  }
}
</script>

<style scoped>
</style>