<template>
  <v-container class="orange lighten-5" rounded>
    <v-app-bar  app elevate-on-scroll elevation="3" color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-col lg="1" cols="12">
        <a
            href="/catalogue"
        ><v-img
            src="https://firebasestorage.googleapis.com/v0/b/frontvue-fiveaday.appspot.com/o/design%2FBouton%20retour.png?alt=media&token=83318c32-d667-4278-b2ed-b3ba8de61a97"
            contain
            max-height="34"
        /></a>
      </v-col>
      <v-col>
        <v-div class=""><v-img
            src="https://firebasestorage.googleapis.com/v0/b/frontvue-fiveaday.appspot.com/o/design%2FH1-catalogue%402x-100.jpg?alt=media&token=b4963f7a-640f-4823-a3e7-5977b2ccf9b3"
            contain
            max-height="50"
            min-width="600"
        /></v-div>
      </v-col>
      <router-link :to="{name: 'Cart'}" class="button teal--text">
        <v-img src="https://firebasestorage.googleapis.com/v0/b/frontvue-fiveaday.appspot.com/o/design%2FPanier-catalogue.svg?alt=media&token=f77837bc-dbeb-4350-aa2f-886805e492fe" elevation="2" height="20px" width="20px"></v-img>({{this.$store.state.cart.length}})
      </router-link>
    </v-app-bar>
    <v-row no-gutters justify="center">
      <v-col cols="12" sm="6" md="4">
        <v-card>
          <v-img
              contain
              :src=this.product.image[0]
              height="350"
              class=""
          ></v-img>
        </v-card>
        <div class="form-group d-flex" >
          <div class="pt-5 pr-3">
            <v-sheet
                class="mx-auto"
                elevation="8"
                max-width="390"
                rounded
            >
              <v-slide-group
                  v-model="model"
                  class="pa-1"
                  active-class="success"
                  show-arrows
              >
                <v-slide-item
                    v-for="image in this.product.image" :key="image"
                >
                  <div class="pa-2">
                    <img :src="image" alt="" width="88px">
                  </div>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </div>

        </div>
      </v-col>
      <v-col cols="6" md="6">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><h1>{{ this.product.name }}</h1></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title><h2>{{ this.product.price }} €</h2></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Caractéristiques</v-list-item-title>
            <v-list-item-subtitle>{{ this.product.description }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item four-line>
          <v-list-item-content>
            <v-list-item-title>Conditionnement</v-list-item-title>
            <v-list-item-subtitle>
              {{ this.product.conditionnement }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item five-line>
          <v-list-item-content>
            <v-row align="center">
              <v-col
                  v-if="catTextile"
                  class="d-flex pl-4"
                  cols="12"
                  sm="6"
              >
                <v-select
                    :items="this.product.size"
                    label="Taille"
                    dense
                    v-model="this.newSize"
                ></v-select>
              </v-col>

              <v-col
                  v-if="catTextile"
                  class="d-flex"
                  cols="12"
                  sm="6"
              >
                <v-select
                    :items="this.product.color"
                    filled
                    label="Couleur"
                    dense
                    v-model="this.newColor"
                ></v-select>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>

        <v-list-item six-line>
          <v-list-item-content class="mt-n9">
            <v-list-item-title><strong>
              quantité :
<!--              <v-form-->
<!--                ref="form"-->
<!--                v-model="valid"-->
<!--                lazy-validation-->
<!--            >-->
<!--              <v-text-field-->
<!--                  v-model.number="this.quantity"-->
<!--                  required-->
<!--              ></v-text-field>-->
<!--            </v-form>-->
              <v-btn icon color="red" @click="down"><v-icon>mdi-minus</v-icon></v-btn>{{ this.quantity }}<v-btn icon color="red" @click="up"><v-icon>mdi-plus</v-icon></v-btn>
            </strong></v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-card-actions>
          <v-btn color="#14ad95" block dark class="withoutupercase mb-2 ml-2"  @click="addToCart">HOP DANS LE PANIER</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "../../main";
import AddToCart from "../../components/AddToCart";
import { mapGetters } from "vuex";
import Topbar from "../../components/Topbar";

export default {
  name: "ProductPage",
  components: AddToCart,
  Topbar,
  // props:{
  //   name: String,
  //   price: Number,
  //   pId: String,
  //   quantity: Number,
  //   size: String,
  //   color: String
  // },
  data() {
    return {
      valid: true,
      model: null,
      orderline: [],
      product:{
        newSize: "",
        newColor: "",
      },
      newSize: "",
      newColor: "",
      loaded: false,
      tailles: "",
      couleur:"",
      counter:0,
      quantity:1
    };
  },
  computed : {
    ...mapGetters(["currentUser", "cart"]),
    displayProduct: function(){
      return this.getProducts()
    },
    catTextile(){
      return (this.product.category === "Textile")
    }
  },
  methods:{
    addToCart(){
      // console.log(this.product.d)
      // this.product.push(this.product.newSize, this.product.newColor)
      this.orderline.push(this.product,this.quantity,this.newSize,this.newColor )
      this.$store.commit("addToCart", this.orderline)
    },
    down(){
      if (this.quantity >= 1){
        this.quantity = this.quantity - 1
      } else {
        this.quantity = 0
      }
    },
    up(){

        this.quantity = this.quantity + 1
    }
  },
  beforeCreate() {
    db.collection("products")
      .where("productId", "==", this.$route.params.id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.product = doc.data();
          console.log(this.product);
        });
        this.loaded = true;
      });
  },
  // methods: {
  //   async getProducts(){
  //     await db.collection("products").get().then((querySnapshot) => {
  //       // const products = []
  //       querySnapshot.forEach((doc) => {
  //         this.products.push(doc.data());
  //       });
  //     }).catch((error) => {
  //       console.log("Error getting documents: ", error);
  //     });
  //   },
};
</script>

<style scoped></style>
