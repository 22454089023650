<template>
  <v-container>
<!--    <v-btn color="#14ad95" block dark class="withoutupercase mb-2" @click="addToCart">HOP DANS LE PANIER</v-btn>-->
    <v-btn
        absolute
        color="#14ad95"
        class="white--text"
        fab
        large
        right
        top
        @click="addToCart"
    >
      <v-icon>mdi-cart</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "AddToCart",
  props:{
    name: String,
    price: Number,
    pId: String,
    quantity: Number,
    size: String,
    color: String
  },
  data(){
    return{
      orderline:{
        productName:this.name,
        productPrice: this.price,
        product_id: this.pId,
        productQuantity:this.quantity,
        productTotal: this.price,
        productSize: this.size,
        productColor: this.color,
        total: 0,
      },
    }
  },
  methods:{
    addToCart(){
      this.$store.commit("addToCart", this.orderline)
    }
  }
}
</script>

<style scoped>

</style>