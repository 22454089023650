import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);

let cart = window.localStorage.getItem("cart")
export default new Vuex.Store({
  state: {
    cart: cart ? JSON.parse(cart) : [],
    status: "",
    order: {
      number: "2",
      date: "22/11/2022",
      user: {}
    },
    currentUser: null,
    currentChannel: null,
    isPrivate: false,
    products: [],
    product: null,
    tools: [],
    docs: [],
    discussions: {},
    messages: [],
    errors: [],
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
    currentChannel: (state) => state.currentChannel,
    isPrivate: state => state.isPrivate,
    order(state) {
      return state.order;
    },
  },
  mutations: {
    //CART
    addToCart(state, orderline){
      let found = state.cart.find(product => product.name == orderline.name);
      if(found){
        found.quantity++
      }else {
        state.cart.push(orderline);
      }

      this.commit("saveCart");
    },
    saveCart(state){
      window.localStorage.setItem("cart", JSON.stringify(state.cart))
    },
    removeFromCart(state, orderline){
      let index = state.cart.indexOf(orderline);
      state.cart.splice(index,1);

      this.commit("saveCart");
    },
    GET_ORDER(state, cart) {
      state.order = {cart, ...state.order};

    },
    //TOOLS
    //DOCS
    //USERS
    SET_USER(state, user) {
      state.currentUser = user;
    },
    SET_CURRENT_CHANNEL(state, channel){
      state.currentChannel = channel
    },
    //Messages
    SET_PRIVATE(state, isPrivate){
      state.isPrivate = isPrivate;
    },
  },
  actions: {
    //Cart
    getOrder({ commit }, cart, user) {
      commit("GET_ORDER", cart, user)
    },
    //Product
    //Tools
    //Document

    //Users
    setUser({ commit }, user) {
      commit("SET_USER", user)
    },
    setCurrentChannel({commit}, channel){
      commit("SET_CURRENT_CHANNEL", channel)
    },
    //Messages
    setPrivate({commit}, isPrivate){
      commit("SET_PRIVATE", isPrivate)
    },
  },
  modules: {},
});
