<template>
  <div>
    <add-category
        v-bind:addCategoryData="addCategoryData"
        v-bind:onAdd="onAdd"
    />

    <categories-list
        v-bind:editId="editId"
        v-bind:editCategoryData="editCategoryData"
        v-bind:onCancel="onCancel"
        v-bind:onDelete="onDelete"
        v-bind:onEdit="onEdit"
        v-bind:onEditSubmit="onEditSubmit"
        v-bind:sortedCategories="sortedCategories"
    />
  </div>
</template>

<script>
import {db} from "../../main";
import AddCategory from './AddCategory'
import CategoriesList from './CategoriesList'
export default {
  name: 'Categories',
  data () {
    return {
      categories: [],
      addCategoryData: {
        category_id: '',
        name: '',
        description: ''
      },
      editId: '',
      editCategoryData: {
        category_id: '',
        name: '',
        description: ''
      }
    }
  },
  components: {
    AddCategory,
    CategoriesList
  },
  computed: {
    sortedCategories () {
      return this.categories.slice().sort((a, b) => {
        return a.category_id - b.category_id
      })
    }
  },
  created () {
    this.getCategories()
  },
  methods: {
    getCategories () {
      db.collection('Categories').get().then(querySnapshot => {
        const categories = []
        querySnapshot.forEach(doc => {
          categories.push(doc.data())
        })
        this.categories = categories
      })
    },
    onAdd () {
      db.collection('Categories').add(this.addCategoryData).then(this.getCategories)
    },
    onDelete (category_id) {
      db.collection('Categories')
          .where('category_id', '==', category_id).get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.delete().then(this.getCategories)
            })
          })
    },
    onEdit (category) {
      this.editId = category.category_id
      this.editCategoryData.name = category.name
      this.editCategoryData.price = category.price
    },
    onCancel () {
      this.editId = ''
      this.editCategoryData.name = ''
      this.editCategoryData.price = ''
    },
    onEditSubmit () {
      db.collection('Categories')
          .where('category_id', '==', this.editId).get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.set({
                category_id: this.editId,
                name: this.editCategoryData.name,
                price: this.editCategoryData.price
              }).then(this.getCategories)
            })
            this.onCancel()
          })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.categories__list-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.categories__add-category {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.material-icons {
  cursor: pointer;
}
</style>