import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";


Vue.config.productionTip = false;
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase/compat";
import { firestorePlugin } from "vuefire";

import "firebase/database";
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");
require("firebase/functions");

const config = {
    apiKey: "AIzaSyCk1kj4XGp4ujBg5CsTK_ktfo8B35hm1vo",
    authDomain: "frontvue-fiveaday.firebaseapp.com",
    databaseURL: "https://frontvue-fiveaday-default-rtdb.firebaseio.com",
    projectId: "frontvue-fiveaday",
    storageBucket: "frontvue-fiveaday.appspot.com",
    messagingSenderId: "941629949126",
    appId: "1:941629949126:web:dc7794cfbd62b81da943c7"
}

firebase.initializeApp(config);
const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()
const functions = firebase.functions()

export {
    firebase,
    auth,
    db,
    storage,
    functions,
}

window.firebase = firebase;

const unsuscribe = firebase.auth().onAuthStateChanged(user => {

    store.dispatch('setUser', user)

    new Vue({
        router,
        store,
        vuetify,
        firestorePlugin,
        render: (h) => h(App),
    }).$mount("#app")

    unsuscribe()
})
