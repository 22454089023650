import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/compat";
import Products from "../components/Products";
import ProductPage from "../views/admin/ProductPage";
import Categories from "../components/Category/Categories";
import Category from "../views/admin/Category";
import File from "../views/admin/File";
import Admin from "../views/admin/Admin";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    beforeEnter: (to, from, next) => {
      if(!firebase.auth().currentUser){
        next("/login");
      }else{
        next()
      }
    }
  },
  {
    path: "/profil",
    name: "profil",
    component: () => import("../views/Profile.vue"),
  },
  {
    name: "login",
    path: "/login",
    component: () => import("../components/Login.vue"),
  },
  {
    name: "user",
    path: "/user",
    component: () => import("../views/admin/UserAdmin"),
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("../views/Profile.vue"),
    props: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    beforeEnter: (to, from, next) => {
      if(!firebase.auth().currentUser){
        next("/login");
      }else{
        next()
      }
    }
  },
  {
    path: "/catalogue",
    name: "Catalogue",
    component: () => import( '../views/Catalogue2.vue')
  },
  {
    path: "/product/:id",
    name: "productDetail",
    component: () => import( '../views/admin/ProductPage.vue'),
    props: true
  },
  {
    path: "/messagerie",
    name: "Messagerie",
    component: () => import( '../views/Messagerie.vue'),
    beforeEnter: (to, from, next) => {
      if(!firebase.auth().currentUser){
        next("/");
      }else{
        next()
      }
    }
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
  },
  {
    path: "/admin/categories",
    name: "Categories",
    component: Categories
  },
  {
    path: "/admin/categories/:category_id",
    name: "CategoryPage",
    component: Category
  },
  {
    path: "/admin/files",
    name: "Files",
    component: () => import( '../components/Files.vue')
  },
  {
    path: "/admin/files/:file_id",
    name: "FilePage",
    component: File
  },
  {
    path: "/admin/products",
    name: "ProductsA",
    component: Products
  },
  {
    path: "/admin/products/:product_id",
    name: "ProductPage",
    component: ProductPage
  },
  {
    path: "/admin/orders",
    name: "order",
    component: () => import( '../views/admin/Order.vue')
  },
  {
    path: "/admin/category",
    name: "category",
    component: () => import( '../views/admin/Category.vue')
  },
  {
    path: "/documentation/legal",
    name: "Legal",
    component: () => import( '../components/File/Legal.vue')
  },
  {
    path: "/documentation/ressources",
    name: "Rh",
    component: () => import( '../components/File/Rh.vue')
  },
  {
    path: "/documentation/process",
    name: "Process",
    component: () => import( '../components/File/Process.vue')
  },
  {
    path: "/products",
    name: "Products",
    component: () => import( '../components/Products.vue')
  },
  {
    path: "/documentation",
    name: "Documentation",
    component: () => import( '../views/Document.vue')
  },
  {
    path: "/outils",
    name: "Tools",
    component: () => import( '../views/Tools.vue')
  },
  {
    path: "/outils/calculator",
    name: "Calculator",
    component: () => import( '../views/Calculator.vue')
  },
  {
    path: "/outils/devis",
    name: "Devis",
    component: () => import( '../views/CalculatorDevis.vue')
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import( '../views/Cart2.vue')
  },
  {
    path: "/creerutilisateur",
    name: "AddUsers",
    component: () => import( '../components/AddUsers')
  },
  {
    path: "/facture/:id",
    name: "Facture",
    component: () => import( '../components/Recap.vue')
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
