<template>
  <div>
    <h3>Add Category</h3>

    <div class="card categories__add-category">
      <div>
        <input
            v-model="addCategoryData.category_id"
            class="validate"
            placeholder="Id"
        >
      </div>
      <div>
        <input
            v-model="addCategoryData.name"
            class="validate"
            placeholder="name"
        >
      </div>
      <div>
        <input
            v-model="addCategoryData.description"
            class="validate"
            placeholder="description"
        >
      </div>
      <div>
        <i
            @click="onAdd"
            class="material-icons medium"
        >
          add
        </i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddCategory',
  props: [
    'addCategoryData',
    'onAdd'
  ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.categories__list-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.categories__add-category {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.material-icons {
  cursor: pointer;
}
</style>