import "material-design-icons-iconfont/dist/material-design-icons.css";
import "font-awesome/css/font-awesome.min.css"; // Ensure you are using css-loader
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";



Vue.use(Vuetify);

import colors from "vuetify/lib/util/colors";


export default new Vuetify({
    icons: {
        iconfont: "md" || "fa",
        values:{
            accueilSvg: {
                component: require('../assets/Sidebar/Icon-Accueil.svg')
            },
            accueilSvgPath:'M14.989,9.491L6.071,0.537C5.78,0.246,5.308,0.244,5.017,0.535c-0.294,0.29-0.294,0.763-0.003,1.054l8.394,8.428L5.014,18.41c-0.291,0.291-0.291,0.763,0,1.054c0.146,0.146,0.335,0.218,0.527,0.218c0.19,0,0.382-0.073,0.527-0.218l8.918-8.919C15.277,10.254,15.277,9.784,14.989,9.491z',
        }
    },
    theme: {
        themes: {
            light: {
                background: colors.grey.lighten2, // Not automatically applied
            },
            dark: {
                background: colors.shades.white, // If not using lighten/darken, use base to return hex
            },
        },
    },
});
