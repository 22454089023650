<template>
  <v-navigation-drawer
      v-if="currentUser"
      v-model="drawer"
      app
  >
    <v-img class="mt-4 mb-4 mr-4 ml-4" src="../assets/LogoFiveaDay.png"></v-img>
    <v-btn
        elevation="2"
        @click="adminState"
    ></v-btn>
    <v-list v-if="this.admin === false">
      <v-list-item
          class="white--text"
          router
          to="/"
      >
        <v-list-item-icon class="mr-2" >
          <v-img :src="require('../assets/Sidebar/Icon-Accueil.svg')" height="20" width="20" contain/>
        </v-list-item-icon>

        <v-list-item-content class="to-left">
          <v-list-item-title>Accueil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          class="white--text"
          router
          to="/dashboard"
      >
        <v-list-item-icon class="mr-2" >
          <v-img :src="require('../assets/Sidebar/Icon-monFAD.svg')" height="20" width="20" contain/>
        </v-list-item-icon>

        <v-list-item-content class="to-left">
          <v-list-item-title>Mon Five a Day</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          class="white--text"
          router
          to="/messagerie"
      >
        <v-list-item-icon class="mr-2" >
          <v-img :src="require('../assets/Sidebar/Icon-Chat.svg')" height="20" width="20" contain/>
        </v-list-item-icon>

        <v-list-item-content class="to-left">
          <v-list-item-title>Papotons</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          class="white--text"
          router
          to="/catalogue"
      >
        <v-list-item-icon class="mr-2">
          <v-img :src="require('../assets/Sidebar/Icon-Catalogue.svg')" height="20" width="20" contain/>
        </v-list-item-icon>

        <v-list-item-content class="to-left">
          <v-list-item-title>Catalogue</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          class="white--text"
          router
          to="/documentation"
      >
        <v-list-item-icon class="mr-2" >
          <v-img :src="require('../assets/Sidebar/Icon-Biblio.svg')" height="20" width="20" contain/>
        </v-list-item-icon>

        <v-list-item-content class="to-left">
          <v-list-item-title>Bibliogra'fruit</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
          class="white--text"
          router
          to="/outils"
      >
        <v-list-item-icon class="mr-2" >
          <v-img :src="require('../assets/Sidebar/Icon-Outils.svg')" height="20" width="20" contain/>
        </v-list-item-icon>

        <v-list-item-content class="to-left">
          <v-list-item-title>Ma boîte à outils</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-spacer />
      </v-list-item>
      <v-list-item>
        <v-btn
            color="red--text"
            @click="logout">Déconnexion</v-btn>
      </v-list-item>

    </v-list>
    <v-list v-if="this.admin === true">
      <v-list-item
          class="white--text"
          v-for="link in links_admin"
          :key="link.text"
          router
          :to="link.route"
      >
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ link.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-spacer />
      </v-list-item>
      <v-list-item>
        <v-btn @click="logout">Déconnexion</v-btn>
      </v-list-item>

    </v-list>

  </v-navigation-drawer>

</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import firebase from "firebase/compat";
import { mapGetters } from "vuex";

export default {
  props:['drawer'],
  name: "Sidebar",
  data(){
    return{
      presenceRef: firebase.database().ref("presence"),
      email:"",
      admin: false,
      links: [
        { icon: "mdi-home", text: "Accueil", route: "/" },
        { icon: "mdi-microsoft-windows", text: "Mon Five a Day", route: "/dashboard" },
        { icon: "mdi-send", text: "Papotons", route: "/messagerie" },
        { icon: "mdi-clipboard-list", text: "Catalogue", route: "/catalogue" },
        { icon: "mdi-inbox-arrow-down", text: "Bibliogra'fruit", route: "/documentation" },
        { icon: "mdi-toolbox", text: "Ma boîte à outils", route: "/outils" },
      ],
      links_admin: [
        { icon: "mdi-microsoft-windows", text: "Accueil", route: "/admin" },
        { icon: "mdi-microsoft-windows", text: "Commandes", route: "/admin/orders" },
        { icon: "mdi-send", text: "Produits", route: "/admin/products" },
        { icon: "mdi-clipboard-list", text: "Documentation", route: "/admin/files" },
        { icon: "mdi-clipboard-list", text: "Documentation", route: "/admin/users" },

      ],
    }
  },
  computed: {
    ...mapGetters(["currentUser", "currentChannel"]),
  },
  methods: {
    logout: function () {
      const auth = getAuth();
      this.presenceRef.child(this.currentUser.uid).remove()
      signOut(auth).then(() => {
        this.$store.dispatch("setUser", null)
        this.$router.push('/login')
      }).catch((error) => {
        console.log(error)
      });
    },
    adminState(){
      if(this.admin === false){
        return this.admin = true
      } else{
        return this.admin = false
      }

    }
  },
}
</script>

<style scoped>
.to-left{
  text-align: left;
  font: normal normal bold 16px/19px Helvetica;
}
</style>