<template>
  <v-app id="inspire">
    <div>
      <Sidebar class="my_menu" :drawer="drawer"/>
    </div>
    <v-main>
      <v-container
          class="py-8 px-6"
          fluid
      >
        <router-view></router-view>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import firebase from "firebase/compat";
export default {
  components: { Sidebar },
  data: () => ({
    cards: ["Today", "Yesterday"],
    drawer: true,
    user: firebase.auth().currentUser
  }),
}
</script>
<style scoped>
.my_menu{
  background-color: #14ad95;
}
</style>