<template>
<h1>Admin</h1>
</template>

<script>
export default {
  name: "Admin"
}
</script>

<style scoped>

</style>